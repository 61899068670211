class ApicaAppConfigCtrl {
  constructor() { }
}

// empty template, no settings for now. Can be separated to an html file and referenced via templateUrl
ApicaAppConfigCtrl.template = `
  <h2>Apica Panels</h2>
`;

class AboutCtrl {
  constructor() { }
}

// note that plugin id (apica-status-panel) is hardcoded here as templateUrl is static
// it isn't clear how to avoid hardcode (like it was done in the constructor using $scope.ctrl.pluginId)
AboutCtrl.templateUrl = '../../plugins/apica-panels-app/components/about.html';

export {
  ApicaAppConfigCtrl as ConfigCtrl,
  AboutCtrl
};
